/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  var $document       = $(document);
  var $window         = $(window);
  var $windowWidth    = $window.width();
  var $windowHeight   = $window.height();
  var $isMedium       = ($windowWidth <= 900) ? true : false;
  var $isSmall        = ($windowWidth <= 640) ? true : false;
  var $scrollTop      = $window.scrollTop();
  var $html           = $('html');
  var $cursorWrap     = $('.cursor-wrap');
  var $cursor         = $('.cursor');
  var $switchingWork  = false;
  var $noHover        = window.matchMedia('(hover: none)');
  var $locomotive     = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    smartphone: {
      smooth: false
    },
    tablet: {
      smooth: true
    }
  });

  var $gutter = 20;
  var $headerHeight = 60;
  var $logoWidth = 250;
  if($isSmall) {
    $gutter = 12;
    $headerHeight = 52;
    $logoWidth = 220;
  } else if($isMedium) {
    $headerHeight = 68;
  }

  $window.resize(function() {
    $windowWidth  = $window.width();
    $windowHeight = $window.height();
    $isMedium     = ($windowWidth <= 900) ? true : false;
    $isSmall      = ($windowWidth <= 640) ? true : false;

    $gutter = 20;
    $headerHeight = 60;
    $logoWidth = 250;
    if($isSmall) {
      $gutter = 12;
      $headerHeight = 52;
      $logoWidth = 220;
    } else if($isMedium) {
      $headerHeight = 68;
    }
  });

  $window.scroll(function(e){
    $scrollTop = $window.scrollTop();
  });

  // cursor movement
  function mousemoveCursor(e) {
    var $x = e.clientX;
    var $y = e.clientY;

    //gsap.killTweensOf($cursor);
    gsap.set($cursor, { x:$x, y:$y });
  }

  function wipeIn(callback) {
    $('#wipe').addClass('is-loading');
    setTimeout(function() {
      $locomotive.scrollTo('top', {
        duration:0,
        disableLerp:true,
        callback:callback
      });
    }, 800);
  }

  function wipeOut() {
    var $loadedImages = 0;
    $document.imagesLoaded().always(function() {
      setTimeout(function() {
        $('#wipe').addClass('done-loading');
        setTimeout(function() {
          $('#wipe').removeClass('is-loading').removeClass('done-loading');
          $('#wipe').get(0).style.setProperty('--progress', '0%');
        }, 800);
      }, 500);
    }).progress(function(instance, image) {
      $loadedImages++;
      var $percentage = ($loadedImages/instance.images.length) * 100;
      $('#wipe').get(0).style.setProperty('--progress', $percentage+'%');
    });
  }

  function homeHero() {
    var $video = $('.video-overlay video');
    $video[0].ontimeupdate = function() {
      var $percentage = ($video[0].currentTime / $video[0].duration) * 100;
      $('.video-overlay_progress').css('width', $percentage+'%');
    };
  }

  function splitHeading() {

    function setupSplitHeading() {
      $('.split-heading h2').each(function() {
        $(this).data('width', $(this).width());
      });
    }
    setupSplitHeading();
  }

  function gallery() {
    var $gallery = $('.gallery_images');
    var $galleryImages = $('.gallery_image');

    function setGalleryPagination() {
      var $total = $galleryImages.length;
      if($total < 10) {
        $total = '0'+$total;
      }

      var $num = $galleryImages.first().data('num');
      if($num < 10) {
        $num = '0'+$num;
      }

      $('.gallery_pagination').html($num+'/'+$total);
    }

    function collapseGallery() {
      gsap.killTweensOf($galleryImages);
      $('.gallery').removeClass('state--expanded');

      $galleryImages.each(function($index) {
        var $image = $(this);

        if($index > 0) {
          var $marginLeft = -$image.width();
          gsap.to($image, 0.5, { marginLeft:$marginLeft });
        } else {
          gsap.to($image, 0.5, { marginLeft:0 });
        }
      });
    }

    function expandGallery() {
      gsap.killTweensOf($galleryImages);
      gsap.to($galleryImages, 0.5, {
        marginLeft:0,
        onComplete:function() {
          $('.gallery').addClass('state--expanded');
        }
      });
    }

    setGalleryPagination();

    if($noHover.matches === true || $isMedium) {
      expandGallery();

      /*var $liveSnap = [];



      Draggable.create('.gallery_images', {
        type:'x',
        inertia:true,
        bounds: {
          minX:$gutter
        }
      });*/
    } else {
      $gallery.mouseover(expandGallery);
      $gallery.mouseout(collapseGallery);
      collapseGallery();
    }

    $('.gallery_images').on('click', function() {
      var $firstImage = $galleryImages.first();
      var $marginLeft = -$firstImage.outerWidth();

      gsap.to($firstImage, 0.5, {
        marginLeft:$marginLeft,
        onComplete:function() {
          $('.gallery_images').append($firstImage);
          $galleryImages = $('.gallery_image');
          setGalleryPagination();
          gsap.set($firstImage, { marginLeft:-$marginLeft });
          gsap.to($firstImage, 0.5, { marginLeft:0 });
        }
      });
    });
  }

  var $options = [
    {
      from: '(.*)',
      to: '(.*)',
      in: function(next) {
        UTIL.loadEvents();
        wipeOut();

        $cursorWrap.removeClass('state--view state--play state--pause state--gallery');
      },
      out: function(next) {
        $html.removeClass('state--mobile-menu-open');
        $cursorWrap.removeClass('state--view state--play state--pause state--gallery');

        wipeIn(next);
      }
    },
    {
      from: '(.*)',
      to: '/contact',
      in: function(next) {
        UTIL.loadEvents();
      },
      out: function(next) {
        $html.removeClass('state--mobile-menu-open');

        gsap.to('#contact .contact-content', 0.5, {
          y:0,
          onComplete:function() {
            $('.contact-content').addClass('is-inview');
            
            setTimeout(next, 500);
          }
        });
      }
    },
    {
      from: '(.*)',
      to: '/work/:id',
      in: function(next) {
        UTIL.loadEvents();
      },
      out: function(next) {
        $html.removeClass('state--mobile-menu-open');
        $cursorWrap.removeClass('state--view');

        var $imageWrapper = $('.work-item.active .work-item_image');
        var $points = $imageWrapper[0].getBoundingClientRect();
        var $video = $imageWrapper.find('video');
        var $image = $imageWrapper.find('img');

        if($video.length) {
          $('#work').html($video);
        } else {
          $('#work').html($image);
        }

        gsap.set('#work', {
          position:'fixed',
          x:$points.left,
          y:$points.top,
          width:$points.width,
          height:$points.height
        });
        $('#work').show();

        gsap.to('#swup', 0.5, { opacity:0 });

        gsap.to('#work', 0.5, {
          x:$gutter,
          y:$headerHeight,
          width:($windowWidth - $gutter - $gutter),
          height:(($windowWidth - $gutter - $gutter) * (9/16)),
          onComplete:function() {
            $locomotive.scrollTo('top', {
              duration:0,
              disableLerp:true,
              callback:function() {
                next();
              }
            });
          }
        });

        //next();
      }
    },
    {
      from: '/work/:id',
      to: '/work/:id',
      in: function(next) {
        UTIL.loadEvents();
      },
      out: function(next) {
        $html.removeClass('state--mobile-menu-open');

        var $imageWrapper = $('.work-placeholder_hero');
        var $points = $imageWrapper[0].getBoundingClientRect();
        var $video = $imageWrapper.find('video');
        var $image = $imageWrapper.find('img');

        if($video.length) {
          $('#work').html($video);
        } else {
          $('#work').html($image);
        }

        gsap.set('#work', {
          position:'fixed',
          x:$points.left,
          y:$points.top,
          width:$points.width,
          height:$points.height
        });
        $('#work').show();

        //gsap.to('#swup', 0.5, { opacity:0 });

        gsap.to('#work', 0.5, {
          x:$gutter,
          y:$headerHeight,
          width:($windowWidth - $gutter - $gutter),
          height:(($windowWidth - $gutter - $gutter) * (9/16)),
          onComplete:function() {
            $locomotive.scrollTo('top', {
              duration:0,
              disableLerp:true,
              callback:function() {
                gsap.set('#swup', { opacity:1 });
                next();
              }
            });
          }
        });
      }
    }
  ];
  var $swup = new Swup({
    animateHistoryBrowsing: true,
    plugins: [new SwupGtmPlugin(), new SwupBodyClassPlugin(), new SwupJsPlugin($options), new SwupPreloadPlugin()]
  });

  $swup.preloadPage('/contact/');

  $swup.on('clickLink', function(e) {
    var $el = $(e.target);

    if($el.parents('a').hasClass('work-item')) {
      $el.parents('a').addClass('active');
    }
  });

  // locomotive
  function locomotiveScroll(args) {
    var $newWidth;

    // Get all current elements : args.currentElements
    if(typeof args.currentElements['split-heading'] === 'object') {
      var $splitHeading = $(args.currentElements['split-heading'].el);
      var $splitHeadingInner = $splitHeading.find('.split-heading_content-inner');
      var $splitHeadingH2 = $splitHeading.find('.split-heading h2');
      var $splitHeadingH2Width = $splitHeadingH2.data('width');
      var $percentageTop = (args.scroll.y - args.currentElements['split-heading'].top) / ($windowHeight / 2);
      var $percentageBottom = false;

      var $divWidth = 120;

      if($splitHeadingInner.length) {
        $percentageBottom = -((args.scroll.y - (args.currentElements['split-heading'].top + args.currentElements['split-heading'].el.offsetHeight - $windowHeight - ($windowHeight / 2))) / ($windowHeight / 2) - 1);
      }

      $newWidth = $splitHeadingH2Width;
      if($percentageBottom && $percentageBottom < 1) {
        if($percentageBottom < 0) {
          $percentageBottom = 0;
        }

        $newWidth = $splitHeadingH2Width + (($windowWidth - $gutter - $gutter) - $splitHeadingH2Width) * $percentageBottom;
        $divWidth = 120 + (($windowWidth - $gutter - $gutter) - 120) * $percentageBottom;
      } else if ($percentageTop > 0) {
        if($percentageTop > 1) {
          $percentageTop = 1;
        }

        $newWidth = $splitHeadingH2Width + (($windowWidth - $gutter - $gutter) - $splitHeadingH2Width) * $percentageTop;
        $divWidth = 120 + (($windowWidth - $gutter - $gutter) - 120) * $percentageTop;
      }

      gsap.set($splitHeadingH2, { width:$newWidth });
      gsap.set($splitHeading, { '--div-width':$divWidth });
    }

    if(typeof args.currentElements['work-placeholder'] === 'object') {
      if(!$switchingWork) {
        $switchingWork = true;
        var $parser = document.createElement('a');
        $parser.href = $('.work-placeholder a').attr('href');
        $swup.loadPage({ url:$parser.pathname });
      }
    }

    if(typeof args.currentElements['home-hero'] === 'object') {
      var $percentage = args.scroll.y / (args.currentElements['home-hero'].el.offsetHeight - $windowHeight);

      if($percentage < 0) {
        $percentage = 0;
      } else if($percentage > 1) {
        $percentage = 1;
      }

      var $startWidth = $('.home-hero_video-wrapper').width();
      var $endWidth = $windowWidth - $gutter - $gutter;
      $newWidth = $startWidth + ($endWidth - $startWidth)*$percentage;

      var $startHeight = $startWidth*(9/16);
      var $endHeight = $windowHeight - $headerHeight - $gutter;
      var $newHeight = $startHeight + ($endHeight - $startHeight)*$percentage;

      gsap.set('.home-hero_video', { width:$newWidth, height:$newHeight });
    }
  }
  $locomotive.on('scroll', locomotiveScroll);

  function init() {
    if($html.hasClass('is-loading')) {
      var $logo = $('.header .logo');
      $logo.data('width', $logo.width());

      var $loadedImages = 0;
      $document.imagesLoaded().always(function(instance) {
        gsap.to($logo, 0.5, { width:$('.header').width() });
        gsap.to($logo, 0.5, {
          top:$('.header .grid').offset().top,
          left:$('.header .grid').offset().left,
          xPercent:0,
          yPercent:0,
          delay:0.5,
          ease:Expo.easeOut,
          onComplete:function() {
            gsap.to($logo, 0.5, {
              width:$logo.data('width'),
              onComplete:function() {
                $html.removeClass('is-loading');
                $logo.attr('style', '');
                UTIL.loadEvents();
              }
            });
          }
        });
        gsap.to($('.header'), 0.5, {
          height:$headerHeight,
          delay:0.5,
          ease:Expo.easeOut
        });
      }).progress(function(instance, image) {
        $loadedImages++;
        var $percentage = ($loadedImages/instance.images.length);
        var $newWidth = $logoWidth + ($('.header').width() - $logoWidth) * $percentage;

        gsap.to($logo, 0.5, {
          width:$newWidth,
          ease:Expo.easeOut
        });
      });
    } else {
      UTIL.loadEvents();
    }

    // mobile menu
    $document.on('click', '.mobile-menu_toggle', function() {
      $html.toggleClass('state--mobile-menu-open');
    });

    // cursor movement
    $document.on('mousemove', mousemoveCursor);

    // cursor click
    $document.on('mousedown', function() {
      $cursorWrap.addClass('state--down');
    });
    $document.on('mouseup', function() {
      $cursorWrap.removeClass('state--down');
    });

    // video cursor
    $document.on('mouseover', '.video-overlay_button', function() {
      $cursorWrap.addClass('state--play');
    });
    $document.on('mouseout', '.video-overlay_button', function() {
      $cursorWrap.removeClass('state--play state--pause');
    });
    $document.on('mouseover', '.video-overlay video', function() {
      if($(this).hasClass('state--playing')) {
        $cursorWrap.addClass('state--pause');
      } else {
        $cursorWrap.addClass('state--play');
      }
    });
    $document.on('mouseout', '.video-overlay video', function() {
      $cursorWrap.removeClass('state--play state--pause');
    });
    $document.on('mouseover', '.video-overlay_close', function() {
      $cursorWrap.removeClass('state--play state--pause');
    });

    // work cursor
    $document.on('mouseover', '.work-item', function() {
      $cursorWrap.addClass('state--view');
    });
    $document.on('mouseout', '.work-item', function() {
      $cursorWrap.removeClass('state--view');
    });

    // gallery cursor
    $document.on('mouseover', '.gallery_images', function() {
      $cursorWrap.addClass('state--gallery');
    });
    $document.on('mouseout', '.gallery_images', function() {
      $cursorWrap.removeClass('state--gallery');
    });

    // home hero video
    $document.on('click', '.video-overlay_button', function() {
      var $videoOverlay = $('.home-hero_video .video-overlay');
      
      $cursorWrap.removeClass('state--play').addClass('state--pause');

      $locomotive.stop();

      $('#overlay').html($videoOverlay);

      var $points = $('.home-hero_video')[0].getBoundingClientRect();
      gsap.set('#overlay', {
        position:'fixed',
        x:$points.left,
        y:$points.top,
        width:$points.width,
        height:$points.height
      });

      gsap.to('#overlay', 0.5, {
        x:0,
        y:0,
        width:'100%',
        height:'100%',
        onComplete:function() {
          $html.addClass('state--video-overlay-open');
        }
      });
    });
    $document.on('click', '.video-overlay_close', function() {
      $html.removeClass('state--video-overlay-open');

      var $points = $('.home-hero_video')[0].getBoundingClientRect();
      gsap.to('#overlay', 0.5, {
        x:$points.left,
        y:$points.top,
        width:$points.width,
        height:$points.height,
        onComplete:function() {
          var $videoOverlay = $('#overlay .video-overlay');
          $('.home-hero_video').prepend($videoOverlay);
          $('#overlay').removeAttr('style');
          $locomotive.start();
          $('.video-overlay video')[0].play();
        }
      });
    });

    $document.on('click', '.video-overlay video', function() {
      var $video = $(this);
      if($video[0].paused) {
        $video[0].play();
        $video.addClass('state--playing');
        $cursorWrap.removeClass('state--play').addClass('state--pause');
      } else {
        $video[0].pause();
        $video.removeClass('state--playing');
        $cursorWrap.removeClass('state--pause').addClass('state--play');
      }
    });
    $document.on('click', '.video-overlay_toggle', function() {
      var $video = $('.video-overlay video');

      if($video[0].paused) {
        $video[0].play();
        $video.addClass('state--playing');
        $cursorWrap.removeClass('state--play').addClass('state--pause');
      } else {
        $video[0].pause();
        $video.removeClass('state--playing');
        $cursorWrap.removeClass('state--pause').addClass('state--play');
      }
    });
  }

  function initCommon() {
    $locomotive.destroy();

    $document.imagesLoaded().always(function() {
      $locomotive.init();
      $locomotive.on('scroll', locomotiveScroll);
    });

    splitHeading();
  }

  function initHome() {
    homeHero();
  }

  function initStudio() {
    $document.imagesLoaded().always(function() {
      //gsap.to('#swup', 0.5, { opacity:1 });

      var $images = [];
      $('.studio-hero_images img').each(function() {
        $images.push($(this).attr('src'));
      });

      function changeImages() {
        var $image1 = $images[Math.floor(Math.random() * $images.length)];
        var $image2 = $images[Math.floor(Math.random() * $images.length)];

        while($image1 === $image2) {
          $image2 = $images[Math.floor(Math.random() * $images.length)];
        }

        $('.studio-hero h1 span').first().css({ backgroundImage: 'url('+$image1+')' });
        $('.studio-hero h1 span').last().css({ backgroundImage: 'url('+$image2+')' });
      }
      changeImages();

      var $imagesInterval = setInterval(changeImages, 100);

      setTimeout(function() {
        clearInterval($imagesInterval);
      }, 3000);
    });

    gallery();
  }

  function initSingleJob() {
    $document.imagesLoaded().always(function() {
      gsap.to('#swup', 0.5, { opacity:1 });
    });
  }

  function initContact() {
    $document.imagesLoaded().always(function() {
      gsap.to('#swup', 0.5, {
        opacity:1,
        onComplete:function() {
          $('.wrap .contact-content').addClass('is-inview');

          setTimeout(function() {
            gsap.set('#contact .contact-content', { y:'-100%' });
            $('#contact .contact-content').removeClass('is-inview');
          }, 500);
        }
      });
    });

    //$('.wrap .contact-content').addClass('in-view');
    setTimeout(function() {
      //$('.wrap .contact-content').addClass('in-view');
    }, 500);
  }

  function initWork() {
  }

  function initSingleWork() {

    // move video/image into hero
    var $video = $('#work video');
    var $image = $('#work img');
    if($video.length) {
      $('.work-hero').html($video);
    } else if($image.length) {
      $('.work-hero').html($image);
    }
    setTimeout(function() {
      $('#work').hide();
    }, 250);

    $switchingWork = false;

    /*if($('#locomotive > .work-placeholder').length) {
      $('#swup .work-hero').replaceWith($('#locomotive > .work-placeholder .work-hero'));
      $('#locomotive > .work-placeholder').remove();
    }
    $('#swup .work-placeholder').insertAfter('#swup');*/

    $document.imagesLoaded().always(function() {
      gsap.to('.work-wrap', 0.5, { opacity:1 });
    });
  }

  function initDefault() {
    $document.imagesLoaded().always(function() {
      gsap.to('#swup', 0.5, { opacity:1 });
    });
  }

  function init404() {
    $document.imagesLoaded().always(function() {
      gsap.to('#swup', 0.5, { opacity:1 });
    });
  }

  init();

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    'common': {
      init: initCommon
    },
    'page_template_home': {
      init: initHome
    },
    'page_template_studio': {
      init: initStudio
    },
    'job_template_default': {
      init: initSingleJob
    },
    'page_template_contact': {
      init: initContact
    },
    'post_type_archive_work': {
      init: initWork
    },
    'work_template_default': {
      init: initSingleWork
    },
    'page_template_default': {
      init: initDefault
    },
    'error404': {
      init: init404
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

})(jQuery); // Fully reference jQuery after this point.